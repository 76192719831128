<template>
    <component
        :is="componentName"
        :auth_token="auth_token"
        :token="token"
        :url="url"
        :coupon_config="coupon_config"
        :calculator_color="calculator_color"
        :employer_grant="employer_grant"
        :color_button="color_button"
        :portal="portal"
    ></component>
</template>

<script>
import CalculatorScooter from './CalculatorScooter.vue'
import CalculatorRoller from './CalculatorRoller.vue'

export default {
    name: 'ScooterCalculatorWrapper',
    components: {
        CalculatorScooter,
        CalculatorRoller,
    },
    props: {
        url: {
            type: String,
            default: '',
        },
        auth_token: {
            type: String,
            default: ''
        },
        token: {
            type: String,
            default: '',
        },
        mode: {
            type: String,
            default: 'scooter',
        },
        coupon_config: {
            type: String,
            default: '',
        },
        calculator_color: {
            type: String,
            default: 'yellow',
        },
        employer_grant: {
            type: String,
            default:
                '{"protection":{"value":1,"disabled":false},"leasing":{"value":0,"disabled":false},"package":{"value":0,"disabled":false},"tax":{"value":0,"disabled":false}}',
        },
        color_button: {
            type: String,
            default: '{"color":{"background":"#ffd400","text":"#000000"}}',
        },
        portal: {
            type: Boolean,
            default: false
        },

    },
    computed: {
        componentName() {
            if (typeof this.mode !== 'string') {
                return
            }

            return `Calculator${this.mode.charAt(0).toUpperCase() + this.mode.slice(1)}`
        },
    },
}
</script>

<style></style>
