import './app.css'

import Vue from 'vue/dist/vue.esm.js'
import Alpine from 'alpinejs'
import * as VueGoogleMaps from 'vue2-google-maps'

import { mountElements } from './helpers/mount.helper'

import LogoSlider from './components/ContentElements/LogoSlider.vue'
import Carousel from './components/ContentElements/Carousel.vue'
import ArticleCarousel from './components/ContentElements/ArticleCarousel.vue'
import DisturberCarousel from './components/ContentElements/DisturberCarousel.vue'
import DealerSearch from './components/DealerSearch/DealerSearch.vue'
import BulletSwitch from './components/ContentElements/BulletSwitch.vue'
import CalculatorWrapper from './components/Calculators/CalculatorWrapper.vue'
import MobileNavigation from './components/Navigation/MobileNavigation.vue'
import { initScrollFade } from './config/scrollFade'
import { setLazyLoading } from './config/lazyloading'
import ScooterCalculatorWrapper from './components/Calculators/ScooterCalculatorWrapper.vue'
import TextImageCarousel from './components/ContentElements/TextImageCarousel.vue'

const dealerSearchElements = document.querySelectorAll('.dealer-search')
const logoSliderElements = document.querySelectorAll('.logo-slider')
const articleCarouselElements = document.querySelectorAll('.article-carousel')
const disturberCarouselElements = document.querySelectorAll('.disturber-carousel')
const textImageCarouselElements = document.querySelectorAll('.text-image-carousel')
const carouselElements = document.querySelectorAll('.carousel')
const calculatorWrapperElements = document.querySelectorAll('.calculator-wrapper')
const bulletSwitchElements = document.querySelectorAll('.bullet-switch')
const mobileNavigationElements = document.querySelectorAll('.mobile-navigation')
const scooterCalculatorWrapperElements = document.querySelectorAll('.scooter-calculator-wrapper')
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDc9HRv6Djdy_SnvXnhI5W8GaTs270O8oA',
        libraries: 'places',
    },
})

mountElements(logoSliderElements, LogoSlider)
mountElements(carouselElements, Carousel)
mountElements(articleCarouselElements, ArticleCarousel)
mountElements(disturberCarouselElements, DisturberCarousel)
mountElements(textImageCarouselElements, TextImageCarousel)
mountElements(dealerSearchElements, DealerSearch)
mountElements(calculatorWrapperElements, CalculatorWrapper)
mountElements(bulletSwitchElements, BulletSwitch)
mountElements(mobileNavigationElements, MobileNavigation)
mountElements(scooterCalculatorWrapperElements, ScooterCalculatorWrapper)
window.axios = require('axios')
window.Alpine = Alpine

document.addEventListener('DOMContentLoaded', function () {
    initScrollFade()
    setLazyLoading()
})

Alpine.start()
