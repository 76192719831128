<template>
    <div>
        <button @click="toggleMenu" :class="{ 'cursor-eResize' : isMenuOpen, 'mr-64 sm:mr-80' : isMenuOpen }" class="absolute top-0 right-0 pt-8 pb-4 pl-24 pr-6 transition-all duration-500 ease-in-out bg-primary sm:p-0 sm:static focus:outline-none">
            <span :class="{ 'transform translate-y-0.4rem rotate-45': isMenuOpen }" class="block mx-auto transition-all duration-300 ease-in-out bg-black my-5px w-25px h-2px"></span>
            <span :class="{ 'opacity-0': isMenuOpen }" class="block mx-auto transition-all duration-300 ease-in-out bg-black my-5px w-25px h-2px"></span>
            <span :class="{ 'transform -translate-y-2 -rotate-45 ': isMenuOpen }" class="block mx-auto transition-all duration-300 ease-in-out bg-black my-5px w-25px h-2px"></span>
        </button>

        <div :class="isMenuOpen ? 'right-0' : '-right-full'" class="fixed inset-y-0 z-10 flex w-64.5 overflow-auto transition-all duration-500 sm:w-80 bg-primary">

            <nav style="margin:0px 25px;" class="flex flex-col pt-6 pb-16">
                <ul ref="menu" class="flex items-start py-3 text-sm font-black tracking-widest text-left uppercase" v-for="(item, index) in menuItems" :key="index">

                    <li v-if="!item.children" class="pl-6"><a :href="item.link" :target="item.target">{{ item.title }}</a></li>

                    <li v-show="item.children">
                        <span :class="{ 'text-xl' : item.active }" class="flex items-center cursor-pointer" @click.prevent="toggleSubMenu">
                            <IconPlus v-if="item.children" class="mb-2 mr-2"/>
                            {{ item.title }}
                        </span>
                        <ul ref="sub-menu" :class="{ 'hidden': !item.active }" class="w-full h-auto py-4 ml-6 top-12 bg-primary">
                            <li v-for="(child, index) in item.children" :key="index" class="py-4">
                                <a ref="sub-menu-link" :href="child.link" :class="{ 'font-bold': child.current && child.active }" class="py-4 pr-8 font-thin tracking-widest text-center uppercase">{{child.title}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <a class="flex pt-6" :href="info">
                    <button class="z-40 px-5 pt-3 pb-2 text-sm font-black tracking-tight uppercase border-2 border-black rounded-full hover:bg-black hover:text-secondary">
                    Infos anfordern               
                    </button>
                 </a>       
                 <a class="flex pt-4" :href="blog">
                    <button class="z-40 px-5 pt-3 pb-2 text-sm font-black tracking-tight uppercase border-2 border-black rounded-full hover:bg-black hover:text-secondary">
                    Blog               
                    </button>
                 </a>    
                 <a  class="flex pt-4 pb-12" :href="login">
                    <button :class="[active == 'active' ? 'bg-white text-black border-white' : 'bg-black text-white border-black']"
                     class="z-40 px-5 pt-3 pb-2 text-sm font-black tracking-tight uppercase border-2 rounded-full hover:bg-white hover:text-black hover:border-white">
                    Login             
                    </button>
                 </a>        
            </nav>
        </div>
    </div>
</template>

<script>
import IconPlus from '../Base/Icons/IconPlus.vue';

export default {
    name: 'MobileNavigation',
    components: {
        IconPlus,
    },
    props: {
        menu: {
            type: String,
            default: "",
        },
        info : {
            type: String,
            default: "",
        },
        login : {
            type: String,
            default: "",
        },
        blog : {
            type: String,
            default: "",
        },
        active : {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            menuItems: JSON.parse(this.menu),
            isMenuOpen: false,
            hasSubMenu: false,
            hasChildren: false,
        }
    },
    methods: {
        toggleMenu() {
            return this.isMenuOpen = !this.isMenuOpen;
        },
        toggleSubMenu(event) {
            const target = event.target;
            const list = target.parentNode.querySelector("ul")        
            const subMenus = this.$refs['sub-menu']

            if (list) {                
                subMenus.forEach(subMenu => {
                    subMenu.classList.add("hidden")                   
                })
                list.classList.toggle("hidden")
            }
        },
    },
}
</script>
