import Vue from 'vue/dist/vue.esm.js'

export function mountElements(selector, component) {
    Array.from(selector).forEach((element) => {
        if (element) {
            new Vue({
                el: element,
                render: (h) =>
                    h(component, {
                        props: {
                            ...element.dataset,
                        },
                    }),
            })
        }
    })
}
