const getImages = () => {
    return document.querySelectorAll('img')
}

const getIntersectionOberserver = () => {
    return new IntersectionObserver(
        function (entries) {
            if (entries[0].isIntersecting === true) {
                if (
                    !entries[0].target.src.includes('/fileadmin/') &&
                    entries[0].target.dataset.src
                ) {
                    entries[0].target.src = entries[0].target.dataset.src
                }
            }
        },
        { threshold: [0] },
    )
}

const setSrc = () => {
    getImages().forEach((img) => {
        getIntersectionOberserver().observe(img)
    })
}

export const setLazyLoading = () => {
    setSrc()
}
